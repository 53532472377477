//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { titleUrl2,logoUrl,bgimgUrl2 } from '/src/utils/config.js';	
import {
	getExercise,
} from "../../api";
import {
	throttle
} from "../../utils/common";
import {
	mapActions
} from "vuex";
import img from "../../assets/img/123.jpg";
import axios from "axios";
import qs from "qs";
import {
	Toast
} from "vant";
import jutils from "jutils-src";
import audioImg from '/src/components/audioAutoPlay.vue'
export default {
	name: "exercise",
	components: {
		audioImg
	},
	data() {
		return {
			bgimgUrl2:'',
			titleUrl2:'',
			logoUrl:'',
			hour: 0,
			titleArr: [],
			oneTitle: [],
			index: 1, //序号
			activeIndex: 100, //选中的index，单选
			activeArr: [], //多选
			checkboxNum: 0, //多选
			activeAnswer: '', //选中结果是否正确 单选
			activeAnswerArr: [], //选中结果是否正确 多选
			fraction: 0, // 分数
			seconds: 1800,
			isMusic: false,
			recordArr: [], //答题历史
			lastQuestion: [], //上一题记录
			isRecord: false,
			num: 0,
			cheackKey: '',
			activeAnswertext: '', //单选正确结果
			activeAnswerArrText: [], //多选的正确结果
			kong: '',
			daan: false,
			xia: 1,
			jieguo: '',
			radio: [],
			activeDaan: '',
			ceshi: [],
			activeDaanText: '',
			flag: false,

			titleNum: 0,
		};
	},
	created() {
		this.titleUrl2 = titleUrl2;
		this.logoUrl = logoUrl;
		this.bgimgUrl2 = bgimgUrl2;

	},
	beforeMount() {},
	mounted() {
		this.countDown();
		this.get_StartExam();
	},
	destroyed() {
		// window.removeEventListener('pagehide', this.removeHandler)
		// window.removeEventListener('popstate', this.removeHandler)
	},
	methods: {
		//触摸屏幕触发播放音乐
		touchMusic(isPlay) {
			if (isPlay) {
				let audioPlay = document.getElementById('bg-music')
				audioPlay.play();
			}
		},

		//20分钟的计时器
		countDown() {
			// let d = parseInt(this.seconds / (24 * 60 * 60))
			// d = d < 10 ? "0" + d : d
			// let h = parseInt(this.seconds / (60 * 60) % 24);
			// h = h < 10 ? "0" + h : h
			let m = parseInt((this.seconds / 60) % 60);
			m = m < 10 ? "0" + m : m;
			let s = parseInt(this.seconds % 60);
			s = s < 10 ? "0" + s : s;
			this.hour = m + ":" + s;
			if (this.seconds == 0) {
				clearInterval(this.Time());
				this.get_Answer(this.endAnswer);
			} else {
				this.Time();
			}
			// console.log(this.hour)
		},
		//定时器没过1秒参数减1
		Time() {
			setTimeout(() => {
				if (this.seconds == 0) {
					clearInterval(this.Time());
				} else {
					this.seconds = this.seconds - 1;
					this.countDown();
				}
			}, 1000);
		},

		//获取题目
		get_StartExam() {
			getExercise().then((res) => {
				/**
				 * 以前方法
				  // 获取全部题目
					var arr = res.data.data.questions;
					let startExam = arr.map((item, index) => {
						return {
							id: item.id,
							title: item.title,
							options: item.options,
							// options: optionsArr,
							answercontent: item.answercontent,
							index: index,
							typedata: item.typedata, //判断单选还是多选
						};
					});
					// // console.log(startExam,"修改后的题")
					this.titleArr = startExam;
					// //获取第一题
					this.oneTitle = this.titleArr[0]; 
				**/


				// 测试方法
				this.titleArr = res.data.data.questions;
				// 测试方法 获取第一题
				this.titleNum = 0;
				this.oneTitle = this.isFlag(this.titleArr[this.titleNum])
				console.log(this.isFlag(this.titleArr[this.titleNum]))
			});
		},
		// 选项添加flag
		isFlag(oneInfo) {
			if (oneInfo.typedata == '1') {
				return oneInfo
			} else {
				let {
					options
				} = oneInfo;
				let option = options.map((item, index) => {
					return {
						answer: item.answer,
						key: item.key,
						px_key: item.px_key,
						value: item.value,
						flag: false,
					}
				})
				let currentTitle = {
					id: oneInfo.id,
					title: oneInfo.title,
					options: option,
					answercontent: oneInfo.answercontent,
					typedata: oneInfo.typedata, //判断单选还是多选
				}
				return currentTitle
			}
		},
		//上一题
		top() {
			if (this.num == 0) {
				this.$toast.fail("已经是第一题")
			} else {
				this.isRecord = true
				this.num--
				//当前题目
				/*
					之前方法 this.lastQuestion = this.titleArr[this.num]
				*/
				this.lastQuestion = this.recordArr[this.num]
				// 当前题目选中的答案
				this.activeDaanText = this.ceshi[this.num]
				// 判断是多选还是单选
				if (this.activeDaanText.length > 1) {
					console.log('多选')
					//获取多选的正确答案
					let toparr = this.titleArr[this.num].options
					let arr = []
					for (let i = 0; i < toparr.length; i++) {
						if (toparr[i].answer == "1") {
							arr.push(toparr[i].px_key)
							this.jieguo = arr.toString()
						}
					}
					console.log(this.jieguo, "正确的结果")
				} else {
					console.log('单选')
					// 单选是否正确
					if (this.radio[this.num] == undefined) {
						this.activeDaan = false
					} else {
						this.activeDaan = true
					}
					let toparr = this.titleArr[this.num].options
					for (let i = 0; i < toparr.length; i++) {
						if (toparr[i].answer == "1") {
							this.jieguo = toparr[i].px_key
							// console.log(this.jieguo,"正确的结果")
						}
					}
				}
				console.log(this.activeDaanText, "当前题目选中的答案")
				console.log(this.titleArr[this.num].options, "当前题目的所有选项")
			}
		},
		//历史记录下一题
		topNext() {
			if (this.num < this.recordArr.length - 1) {
				// console.log(this.num)
				this.num++
				//当前题目
				/**
				 * 之前方法 this.lastQuestion = this.titleArr[this.num]
				 * **/

				this.lastQuestion = this.recordArr[this.num]
				// console.log(this.lastQuestion)
				// 当前题目选中的答案
				this.activeDaanText = this.ceshi[this.num]
				//判断下一题是多选还是单选
				if (this.activeDaanText.length > 1) {
					console.log('多选')
					//获取多选的正确答案
					let toparr = this.titleArr[this.num].options
					let arr = []
					for (let i = 0; i < toparr.length; i++) {
						if (toparr[i].answer == "1") {
							arr.push(toparr[i].px_key)
							this.jieguo = arr.toString()
						}
					}
					console.log(this.jieguo, "正确的结果")
				} else {
					console.log('单选')
					// 单选是否正确
					if (this.radio[this.num] == undefined) {
						this.activeDaan = false
					} else {
						this.activeDaan = true
					}
					let toparr = this.titleArr[this.num].options
					for (let i = 0; i < toparr.length; i++) {
						if (toparr[i].answer == "1") {
							this.jieguo = toparr[i].px_key
							console.log(this.jieguo)
						}
					}
				}

				// 单选用的
				// this.activeDaan = this.radio[this.num]

				// console.log(this.num)
			} else {
				this.num++
				this.isRecord = false
			}

		},
		// 当前题答案
		daans(e) {
			if (this.activeIndex == 100) {
				this.$toast.fail("请选择")
			} else {
				if (e == 1) {
					// console.log(this.activeAnswer, "单选")
					// 判断是否得分
					if (this.activeAnswer == "1") {
						this.fraction = this.fraction + 4
						this.activeAnswer = ''
					}
					// 判断正错提示
					this.radio.push(this.activeAnswer)
					// console.log(this.radio)
					// 拿到已做题目的答案
					this.ceshi.push(this.cheackKey)
					// console.log(this.ceshi)
					let t = this.oneTitle.options
					for (let i = 0; i < t.length; i++) {
						if (t[i].answer == '1') {
							// console.log(t[i].px_key)
							// 提示正确答案
							this.activeAnswertext = t[i].px_key
						}
					}
					this.daan = true
					this.xia = 2
				} else if (e == 2) {
					if (this.activeArr.length < 2) {
						this.$toast.fail('多选题')
					} else {
						// 判断是否得分
						console.log(this.activeAnswerArr, "多选")
						let duoxuan = this.activeAnswerArr.every(x => {
							return x.answer
						})
						// console.log(duoxuan, "是否得分")
						if (duoxuan) {
							this.fraction = this.fraction + 4
							this.activeAnswerArr = []
						}
						//拿到已经选择的答案
						this.ceshi.push(this.activeArr)
						// console.log(this.ceshi)
						//判断当前题是否正确
						let t = this.oneTitle.options
						this.activeAnswerArrText = []
						for (let i = 0; i < t.length; i++) {
							if (t[i].answer == '1') {
								// console.log(t[i].px_key)
								// 提示正确答案
								this.activeAnswerArrText.push(t[i].px_key)
							}
						}
						// 多选判断正确答案跟已选答案是否一样
						// 1.判断长度是否一样
						let flag = true
						if (this.activeAnswerArrText.length != this.activeArr.length) {
							flag = false
						} else {
							this.activeAnswerArrText.forEach(item => {
								if (this.activeArr.indexOf(item) == -1) {
									flag = false
								}
							})
						}
						this.flag = flag;
						// console.log(this.activeAnswerArrText,"正确答案")
						// console.log(this.activeArr,"选中的答案")
						this.daan = true;
						this.xia = 2;
					}

				}

			}
		},
		//下一题
		next() {
			if (this.activeIndex === 100) {
				this.$toast.fail("请选择")
			} else {
				// // 获取上一题答题历史
				this.recordArr.push(this.oneTitle)
				console.log(this.recordArr, "下一题按钮")
				console.log(this.activeAnswerArr, "多选题选中的结果")
				// // 已答题的数组长度
				this.num = this.recordArr.length
				// console.log(this.activeIndex);
				// console.log(thlis.activeAnswer)
				let arr = this.recordArr[0].options
				//判断是否加分
				/* 	if (this.activeAnswer == "1") {
						this.fraction = this.fraction + 4
						this.activeAnswer = ''
					} */
				this.daan = false
				// 是否是最后一题
				if (this.recordArr.length == this.titleArr.length) {
					this.$toast.fail("答题完成")
					// let fraction = this.fraction
					// let time = this.hour
					this.$router.push({
						name: 'exercisecj',
						query: {
							frantion: this.fraction,
							time: this.hour
						}
					})
				} else {
					// 进入下一题清除选中的样式
					this.activeIndex = 100
					//获取下一题
					// this.oneTitle = this.titleArr[this.oneTitle.index + 1]
					// this.xia = 1

					//测试方法
					this.titleNum++
					this.oneTitle = this.isFlag(this.titleArr[this.titleNum]);
					this.xia = 1;
					console.log(this.titleArr[this.titleNum])
				}
				// this.kong = ''
			}
			// console.log(this.oneTitle);
		},
		//选择答案
		selected(item, index, e) {
			// console.log("单选还是多选",e)
			if (e == 1) {
				this.activeIndex = index;
				// 是否答对
				this.activeAnswer = item.answer;
				this.cheackKey = item.px_key
			} else if (e == 2) {
				// console.log(this.titleArr,'所有题目')
				//多选
				this.activeArr = [];
				this.activeAnswerArr = [];
				item.flag = !item.flag;
				this.oneTitle.options.forEach(x => {
					if (x.flag) {
						//已选择的答案
						this.activeArr.push(x.px_key)
						//用来判断已选择的答案是否正确
						// this.activeAnswerArr.push(x.answer)
						this.activeAnswerArr.push(x)
					}
				})
				this.activeIndex = 2
				// console.log(this.activeAnswerArr, "1111")
			}
			// console.log(this.ceshi);
		},
	},
	beforeDestroy() {
		// clearInterval(this.timer)
		// clearInterval(this.timerNum)
	},
	watch: {
		/*    second: {
		      handler(newVal) {
		        this.num(newVal)
		      }
		    },
		    minute: {
		      handler(newVal) {
		        this.num(newVal)
		      }
		    },*/
		list: {
			handler(newVal) {
				//this.get_Time()
			},
		},
	},
	computed: {
		/*second() {
		  return this.num(this.seconds)
		},
		minute() {
		  return this.num(this.minutes)
		}*/
	},
};
